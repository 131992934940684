/** @jsx jsx */
import { jsx, Styled, Container, Box, Flex } from "theme-ui"
import Layout from "../components/layout"
import { Link } from "gatsby"
import LeftArrow from "../icons/left-arrow"
import kitty from "../../static/surprisedkitty.png"

const Index = () => (
  <Layout>
    <Container sx={{ mt: 2, textAlign: `center` }}>
      <Styled.h1>404</Styled.h1>
      <Styled.p>There's no page here! Now that you're here though, please enjoy this terrible joke...</Styled.p>
      <Container>
        <Flex sx={{ justifyContent: `space-between` }}>
            <Box sx={{ maxWidth: `580px`, marginTop: 6 }}>
              <code>A programmer is heading out to the grocery store. His wife tells him "get a gallon of milk, and if they have eggs, get a dozen." He returns with 13 gallons of milk.</code>
            </Box>
            <Box sx={{ maxWidth: `300px`, display: [`none`, `block`, `block`, `block`]}}>
              <div>
                <img
                  src={kitty}
                  alt="Whaaaaaa?"
                  sx={{
                    width: `350px`,
                  }}
                />
              </div>
            </Box>
        </Flex>
      </Container>
      <Styled.a
        as={Link}
        to="/"
        sx={{
          display: `grid`,
          gridTemplateColumns: `35px 1fr`,
          gridColumnGap: 3,
          backgroundColor: `indigo.1`,
          borderRadius: `lg`,
          boxShadow: `md`,
          p: 3,
          mt: 2,
          alignItems: `center`,
          color: `indigo.7`,
          svg: {
            height: `35px`,
            width: `35px`,
            transition: `transform 0.3s ease-in-out`,
          },
          "&:hover": { textDecoration: `none`, color: `indigo.9`, svg: { transform: `translateX(-7px)` } },
          ".primary": {
            color: `indigo.2`,
          },
          ".secondary": {
            color: `indigo.6`,
          },
        }}
      >
        <LeftArrow />
        {` `}
        <div sx={{ fontSize: 1, fontWeight: `medium` }}>
          This way back to safety
        </div>
      </Styled.a>
    </Container>
  </Layout>
)

export default Index
